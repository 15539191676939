@use "veriables";

.about_preload {
    display: none;
}

.about_box {
    display: flex;
    justify-content: flex-start;
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    background-color: veriables.$brand-pink;
    background: linear-gradient(to bottom, veriables.$brand-pink 70%, veriables.$pink-gradient1 85%, veriables.$pink-gradient2 100%);
    color: veriables.$light;
    overflow-x: hidden;
}

.about_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    margin-left: 13vw;
    animation: slide-in-left 0.7s ease-in-out;
}

.about_name {
    margin: 2.1rem 0;
    font-size: veriables.$very-large;
    font-weight: 700;
}

.about_line {
    border-left: 1px veriables.$light;
    height: 77vh;
    margin: 0 5vw;
    align-self: center;
    animation: fade-in 0.7s ease-in;
}

.about_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    height: 100vh;
    max-height: 100vh;
    padding-right:10vh;
}

.about_text {
    width: 32vw;
    animation: slide-in-right 0.7s ease-in-out;
}

.about_text p {
    font-size:veriables.$small;
    font-weight: 300;
    margin: 0.6rem 0;
}

.about_navigation {
    display: flex;
    box-sizing: border-box;
    height: 13vh;
    animation: fade-in 0.7s ease-in;
}

.about-link_A {
    display: flex;
    flex-direction: column;
    width: 34vh;
    height: calc(34vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_A.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    margin-left: 3vw;
    transition: box-shadow 0.08s ease-in-out;
}

.about-link_A:hover {
    box-shadow: -4px -4px veriables.$light;
}

.about-link_A p {
    color: veriables.$brand-pink;
    font-size: veriables.$medium;
    font-weight: 700;
}


.about-link_B {
    display: flex;
    flex-direction: column;
    width: 34vh;
    height: calc(34vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_B.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
    transition: box-shadow 0.08s ease-in-out;
}

.about-link_B:hover {
    box-shadow: 4px 4px veriables.$light;
}

.about-link_B p {
    color: veriables.$brand-pink;
    font-size: veriables.$medium;
    font-weight: 700;
}

.profile_picture {
    width: 20vw;
    height: calc(20vw * 1.2);
}

@keyframes slide-in-right {
    0% {
        transform: translateX(50vw) skew(45deg);
        opacity: 0;
    }

    100% {
        transform: translateX(0) skew(0deg);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translate(-50vw) skew(-45deg);
        opacity: 0;
    }

    100% {
        transform: translate(0) skew(0deg);
        opacity: 1;
    }
}

@keyframes fade-in {

    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@media screen and (max-width:1200px) {
    .about_text {
        width: 50vw;
    }

    .about_line {
        height: 65vh;
    }
}

@media screen and (max-width: 1068px) {
    .about_name {
        font-size: veriables.$large;
    }

    .about_line {
        height: 55vh;
    }

    .about-link_A {
        width: 30vh;
        height: calc(30vh / 2.67);
    }

    .about-link_B {
        width: 30vh;
        height: calc(30vh / 2.67);
    }

    .about-link_A p {
        font-weight: 500;
    }

    .about-link_B p {
        font-weight: 500;
    }
}

@media screen and (max-width: 992px) {
    .about_left {
        margin-left: 5vw;
    }

    .profile_picture {
        width: 25vw;
        height: calc(25vw * 1.2);
    }

    .about_right {
        padding-right: 5vw;
    }

    .about-link_A {
        width: 26vh;
        height: calc(26vh / 2.67);
    }

    .about-link_B {
        width: 26vh;
        height: calc(26vh / 2.67);
    }
}

@media screen and (max-width: 768px) {
    .about_box {
        flex-direction: column;
        justify-content: flex-start;
        height: 200vh;
        max-height: 200vh;
    }

    .about_left {
        justify-content: flex-end;
        align-items: center;
        height: 80vh;
        margin-left: 0;
    }
    .about_name-break {
        display: none;
    }

    .about_name {
        order: 1;
        margin: 2.1rem 0;
        margin-bottom: 0;
    }

    .profile_picture {
        width: 45vw;
        height: calc(45vw * 1.2);
        min-height: 360px;
        min-width: 300px;
    }

    .about_line {
        border-left: none;
        border-top: 1px veriables.$light;
        border-right: none;
        height: 1px;
        width: 95vw;
        margin: 3vh 0;
        margin-top: 1vh;
        align-self: center;
    }

    .about_right {
        justify-content: flex-start;
        align-items: center;
        height: 80vh;
        max-height: 80vh;
        padding-right: 0;
    }

    .about_text {
        margin-top: 10vh;
        width: 90vw;
    }

    .about_text p {
        font-size: veriables.$medium;
    }

    .about-link_A {
        margin-top: 10vh;
    }
    
    .about-link_B {
        margin-top: 10vh;
    }
}

@media screen and (max-width: 426px) {
    .about_box {
        margin-left: 0;
    }

    .about_left {
        height: 60vh;
        margin-top: 15vh;
        justify-content: flex-start;
    }

    .profile_picture {
        width: auto;
        height: 80%;
        min-height: 80%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .about_text {
        margin-top: 5vh;
        width: 90vw;
    }

    .about-link_A {
        width: 18vh;
        height: calc(18vh / 2.67);
    }

    .about-link_B {
        width: 18vh;
        height: calc(18vh / 2.67);
    }
}