@use "veriables";

.project_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;
    background-color: veriables.$light;
    min-height: 100vh;
    color: veriables.$dark;
    overflow-x: hidden;
}

.project_close-box {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    width: 100%;
}

.project_close {
    background-image: url(../images/button_close.svg);
    width: calc(6vw * 0.54);
    height: calc(6vw * 0.54);
    min-width: 36px;
    min-height: 36px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 4vw;
    transition: transform 0.2s ease-in-out;
}

.project_close:hover {
    transform: scale(1.1);
}

.project_title {
    margin: 15vh 0;
    font-size: veriables.$very-large;
    font-weight: 700;
    padding: 0 13vw;
    animation: fade-in 0.7s ease-in-out;
}

.project_description-right {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 40vh;
    padding: 0 10vw;
    margin: 10vh 0;
    box-sizing: border-box;
    flex-wrap: nowrap;
    animation: fade-in 0.8s ease-in-out;
}

.project_description-left {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 40vh;
    padding: 0 10vw;
    margin: 10vh 0;
    box-sizing: border-box;
    flex-wrap: nowrap;
    animation: fade-in 0.8s ease-in-out;
}

.projext_partial-picture {
    display: flex;
    align-self: flex-end;
    width: 35vw;
    height: 100%;
    overflow:hidden;
    justify-content: center;
}

.project_picture {
    width: auto;
    height: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.project_partial-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 40vw;
}

.project_text {
    font-size: veriables.$medium-s;
    font-weight: 300;
    margin: 0;
}

@keyframes fade-in {

    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@media screen and (max-width: 992px) {
    .project_title {
        padding-left: 7vw;
        margin-top: 7vh;
    }

    .project_description-right {
        padding: 0 7vw;
    }
    
    .project_description-left {
        padding: 0 7vw;
    }

    .project_partial-text {
        width: 45vw;
    }
}
@media screen and (max-width: 768px) {
    .project_box {
        align-items: flex-start;
    }

    .project_title {
        font-weight: 500;
        padding: 0;
        align-self: center;
    }

    .project_description-right {
        flex-direction: column;
        width: 100vw;
        justify-content: flex-start;
        margin-top: 3vh;
        padding-right: 0;
    }

    .project_description-left {
        flex-direction: column;
        width: 100vw;
        justify-content: flex-start;
        margin-top: 3vh;
        padding-right: 0;
    }

    .project_partial-text {
        width: 100%;
        justify-content: flex-start;
        align-content: flex-start;
        order: 1;
        margin-right: 1vw;
    }

    .project_partial-text p {
        margin-right: 3vw;  
    }

    .projext_partial-picture {
        width: 60vw;
        min-width: 270px;
        height: 35vh;
        align-self: flex-start;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 425px) {
    .project_title {
        margin-top: 5vh;
        margin-bottom: 7vh;
    }
}
