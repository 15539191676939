@use "veriables";

.notFound_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: veriables.$dark;
}

.notFound_text {
    font-weight: 700;
    font-size: veriables.$grand;
    color: veriables.$light;
    animation: glitch 1s linear infinite;
}

.notFound_link {
    text-decoration: none;
    font-weight: 500;
    font-size: veriables.$very-large;
    color: veriables.$highlight;
}

  
@keyframes glitch {
    2%,64% {
      transform: translate(2px,0) skew(5deg);
    }
    4%,60% {
      transform: translate(-2px,0) skew(0deg);
    }
    62% {
      transform: translate(0,0) skew(-5deg); 
    }
  }
  