@use "veriables";

.contact_resources {
    display: none;
}

.contact_box{
    display: flex;
    height: 100vh;
    width: 100vw;
    max-width: 100vw;
    background-color: veriables.$brand-blue;
    background: linear-gradient(to bottom, veriables.$brand-blue 70%, veriables.$blue-gradient1 85%, veriables.$blue-gradient2 100%);
    flex-wrap: wrap;
    color: veriables.$light;
    overflow-x: hidden;
}

.contact_content {
    display: flex;
    width: 100%;
    height: 68vh;
    padding-top: 4vh;
    box-sizing: border-box;
}

.contact_intro {
    display: flex;
    flex-direction: column;
    height: 68vh;
    width: 68vw;
    justify-content: center;
    padding-left: 13vw;
    animation: slide-in-left 0.7s ease-in-out;
}

.contact_name {
    font-size: veriables.$very-large;
    font-weight: 700;
    margin: 1rem 0;
}

.contact_description {
    font-size: veriables.$medium;
    font-weight: 300;
    margin: 1rem 0;
    width: 35vw;
}

.contact_line {
    border-left: 1px veriables.$light;
    height: 50vh;
    margin: 0 1.5vw;
    align-self: flex-end;
    animation: fade-in 0.7s ease-in;
}

.contact_links {
    box-sizing:border-box;
    display: flex;
    flex-direction: column;
    height: 68vh;
    width: 32vw;
    justify-content: center;
    padding-right: 13vw;
    font-size: veriables.$medium;
    font-weight: 300;
    align-items: flex-start;
    animation: slide-in-right 0.7s ease-in-out;
}

.contact_links-link {
    text-decoration: none;
    margin: 0;
    font-size: veriables.$medium;
    font-weight: 300;
    color: veriables.$light;
    transition: color 0.2s ease-in-out;
}

.contact_links-link:hover {
    color: veriables.$highlight;
}

.contact_email {
    text-decoration: none;
    color: veriables.$light;
    margin: 2rem 0;
    font-weight: 700;
    transition: color 0.2s ease-in-out;
}

.contact_email:hover {
    color: veriables.$highlight;
}

.contact_navigation {
    display: flex;
    height: 32vh;
    width: 100%;
    padding-left: 13vw;
    align-items: center;
    animation: fade-in 0.7s ease-in-out;
}

.contact-link_A {
    display: flex;
    flex-direction: column;
    width: 34vh;
    height: calc(34vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_A.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-left: 3vw;
    transition: box-shadow 0.08s ease-in-out;
}

.contact-link_A:hover {
    box-shadow: -4px -4px veriables.$light;
}

.contact-link_A p {
    color: veriables.$brand-blue;
    font-size: veriables.$medium;
    font-weight: 700;
}

.contact-link_B {
    display: flex;
    flex-direction: column;
    width: 34vh;
    height: calc(34vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_B.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.08s ease-in-out;
}

.contact-link_B:hover {
    box-shadow: 4px 4px veriables.$light;
}

.contact-link_B p {
    color: veriables.$brand-blue;
    font-size: veriables.$medium;
    font-weight: 700;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(50vw) skew(45deg);
        opacity: 0;
    }

    100% {
        transform: translateX(0) skew(0deg);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translate(-50vw) skew(-45deg);
        opacity: 0;
    }

    100% {
        transform: translate(0) skew(0deg);
        opacity: 1;
    }
}

@keyframes fade-in {

    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@media screen and (max-width: 1300px) {
    
    .contact_intro {
        padding-left: 7vw;
    }

    .contact_links {
        padding-right: 7vw;
    }

    .contact-link_A p {
        font-weight: 500;
    }

    .contact-link_B p {
        font-weight: 500;
    }

    .contact_navigation {
        padding-left: 7vw;
    }
}

@media screen and (max-width: 992px) {
    
    .contact-link_A {
        width: 24vh;
        height: calc(24vh / 2.67);
    }

    .contact-link_B {
        width: 24vh;
        height: calc(24vh / 2.67);
    }

    .contact_email {
        font-weight: 500;
    }

    .contact_name {
        margin: 2rem 0;
    }
    
    .contact_description {
        margin: 2rem 0;
        width: 47vw;
    }

    .contact_links-link {
        margin: 0.5rem;
    }
}

@media screen and (max-width: 768px) {
    .contact_box{
        display: flex;
        flex-direction: column;
        max-width: 100vw;
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    .contact_content {
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        max-width: 100vw;
        height: 70vh;
        margin-top: 13vw;
        padding-left: 4vw;
        box-sizing: border-box;
    }

    .contact_intro {
        width: 100%;
        box-sizing: border-box;
        padding: 0 4vw;
        padding-left: 0;
    }

    .contact_name {
        font-size: veriables.$large;
    }

    .contact_description {
        width: 70vw;
        margin: 4vw 0;
    }

    .contact_line {
        border-left: none;
        border-right: none;
        border-top: 1px veriables.$light;
        width: 70vw;
        margin: 2vh 0;
        margin-left: 2vw;
        align-self: flex-start;
        order: 1;
        height: 1px
    }

    .contact_links {
        width: 100%;
    }

    .contact_email {
        margin-right: 4rem;
    }

    .contact_links-link {
        margin: 0.2rem;
    }


    .contact_navigation {
        height: 25vh;
        align-items: center;
        margin-left: 4vw;
        padding: 0;
    }

    .contact-link_A {
        width: 21vh;
        height: calc(21vh / 2.67);
    }

    .contact-link_B {
        width: 21vh;
        height: calc(21vh / 2.67);
    }
}

@media screen and (max-width: 426px) {
    .contact_description {
        width: 90vw;
    }

    .contact_links {
        flex-direction: row;
        width: 100%;
        height: 30vh;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .contact_email {
        margin-right: 40vw;
    }

    .contact_links-link {
        margin-right: 20vw;
    }

    .contact-link_A {
        width: 18vh;
        height: calc(18vh / 2.67);
    }

    .contact-link_B {
        width: 18vh;
        height: calc(18vh / 2.67);
    }
}