$brand-blue: #0A2C59;
$blue-gradient1:#0A2649;
$blue-gradient2:#0A203D;
$brand-pink: #F20544;
$pink-gradient1: #D9043D;
$pink-gradient2: #BF0436;
$highlight: #F2CB05;
$dark: #131516;
$light: #F2F3F4;

$grand: 7rem; //main title
$very-large: 3.5rem; // landing description, all page titles, links in navigation
$large: 3.1rem; // text on link buttons on landing page 
$medium: 2.3rem; // description text, links on contact page, email in navigation
$medium-s: 1.8rem;
$small: 1.2rem; // list of services, name of current project, detailed description text on project page
$very-small: 1rem; // my role, brief description of current project


@font-face {
    font-family: "blender";
    src: url("../fonts/blenderpro-book-webfont.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "blender";
    src: url("../fonts/blenderpro-medium-webfont.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "blender";
    src: url("../fonts/blenderpro-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

* {
    font-family: "blender";
}

html {
    font-size: 16px;
}

@media screen and (max-width: 1200px) {
    html {
        font-size: 14px;
    }
}

@media screen and (max-width: 992px) {
    html {
        font-size: 12px;
    }
}

@media screen and (max-width: 768px) {
    html {
        font-size: 10px
    }
}

html {
    background-color: #131516;
}