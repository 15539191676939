@use "veriables";

.portfolio_preload {
    display: none;
}

.portfolio_box {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-color: veriables.$brand-blue;
    background: linear-gradient(to bottom, veriables.$brand-blue 50%, veriables.$blue-gradient1 80%, veriables.$blue-gradient2 100%);
    color: veriables.$light;
    box-sizing: border-box;
    overflow-x: hidden;
}

.portfolio_description {
    display: flex;
    flex-direction: column;
    height: 40%;
    width: 100%;
    box-sizing: border-box;
    padding-left: 13vw;
    margin-top: 15vh;
    margin-bottom: 10vh;
}

.portfolio_name {
    margin: 1rem 0;
    font-size: veriables.$very-large;
    font-weight: 700;
    animation: slide-in-left 0.7s ease-in-out;
}

.portfolio_subtitle {
    margin: 0.5rem 0;
    font-size: veriables.$medium;
    font-weight: 300;
    animation: slide-in-left 0.7s ease-in-out;
}

.portfolio_skill_list_name{
    margin-top: 5.5rem;
    margin-bottom: 0;
    font-size: veriables.$medium-s;
    font-weight: 700;
    animation: slide-in-right 0.7s ease-in-out;
}

.portfolio_list {
    display: flex;
    animation: slide-in-right 0.7s ease-in-out;
}

.portfolio_list ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-right: 8vw;
}

.portfolio_list ul li {
    font-size: veriables.$medium-s;
    font-weight: 300;
    margin: 0.2rem 0;
}

.portfolio_presentation {
    display: flex;
    justify-content: flex-start;
    height: calc(45vw / 2);
    width: 100%;
    box-sizing: border-box;
    padding-left: 11vw;
    animation: fade-in 0.7s ease-in;
}

.portfolio_presentation-picture-box {
    width: 40vw;
    height: calc(45vw / 2);
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.portfolio_presentation-picture {
    width: auto;
    height: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.portfolio_presentation-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 1.3rem;
    width: 20vw;
    min-width: 20vw;
    height: calc(45vw / 2);
}

.portfolio_presentation-description p {
    margin: 0;
}

.portfolio_presentation-description_role {
    font-weight: 300;
    padding: 1rem 0;
    font-size: veriables.$very-small;
}

.portfolio_presentation-description_bottom {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.portfolio_presentation-description_name {
    font-size: veriables.$medium-s;
    font-weight: 500;
}

.portfolio_presentation-description_description {
    font-weight: 300;
    font-size: veriables.$very-small;
    line-height: 1.3rem;
}

.portfolio_presentation-buttons {
    display: flex;
    width: 20vw;
    justify-content: flex-end;
    height: calc(45vw / 2);;
    align-items: center;
}

.portfolio_presentation-buttons_previous {
    background-image: url(../images/button_previous.svg);
    background-color: veriables.$brand-blue;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    width: 6vw;
    height: 4.5vw;
    border: none;
    margin: 0.5rem;
}

.portfolio_presentation-buttons_next {
    background-image: url(../images/button_next.svg);
    background-color: veriables.$brand-blue;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    width: 6vw;
    height: 4.5vw;
    border: none;
    margin: 0.5rem;
    align-self: bottom;
}

.portfolio_navigation {
    display: flex;
    justify-content: flex-start;
    width: 100vw;
    box-sizing: border-box;
    padding-left: 13vw;
    margin-bottom: 5vh;
    animation: fade-in 0.7s ease-in;
}

.portfolio-link_A {
    display: flex;
    flex-direction: column;
    width: 34vh;
    height: calc(34vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_A.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-top: 13vh;
    margin-left: 3vw;
    transition: box-shadow 0.08s ease-in-out;
}

.portfolio-link_A:hover {
    box-shadow: -4px -4px veriables.$light;
}

.portfolio-link_A p {
    color: veriables.$brand-blue;
    font-size: veriables.$medium;
    font-weight: 700;
}

.portfolio-link_B {
    display: flex;
    flex-direction: column;
    width: 34vh;
    height: calc(34vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_B.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-top: 13vh;
    transition: box-shadow 0.08s ease-in-out;
}

.portfolio-link_B:hover {
    box-shadow: 4px 4px veriables.$light;
}

.portfolio-link_B p {
    color: veriables.$brand-blue;
    font-size: veriables.$medium;
    font-weight: 700;
}

@keyframes slide-in-right {
    0% {
        transform: translateX(50vw) skew(45deg);
        opacity: 0;
    }

    100% {
        transform: translateX(0) skew(0deg);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translate(-50vw) skew(-45deg);
        opacity: 0;
    }

    100% {
        transform: translate(0) skew(0deg);
        opacity: 1;
    }
}

@keyframes fade-in {

    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@media screen and (max-width: 992px) {
    .portfolio_description {
        padding-left: 7vw;
        margin-top: 20vh;
    }

    .portfolio_presentation {
        padding-left: 7vw;
    }

    .portfolio_navigation {
        padding-left: 7vw;
    }

    .portfolio-link_A {
        width: 26vh;
        height: calc(26vh / 2.67);
    }

    .portfolio-link_B {
        width: 26vh;
        height: calc(26vh / 2.67);
    }

    .portfolio-link_A p {
        font-weight: 500;
    }

    .portfolio-link_B p {
        font-weight: 500;
    }
}

@media screen and (max-width: 768px) {
    .portfolio_description {
        padding-left: 7vw;
        padding-right: 3vw;
    }

    .portfolio_presentation {
        height: 80vh;
        width: 100%;
        padding-left: 0;
        margin-top: 5vh;
        flex-wrap: wrap;
    }

    .portfolio_presentation-picture-box {
        width: 80vw;
        height: 60vh;
        overflow: hidden;
        display: flex;
        justify-content: center;
        margin-left: 7vw;
    }
    
    .portfolio_presentation-picture {
        width: auto;
        height: 100%;
        min-height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .portfolio_presentation-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 7vw;
        width: 50vw;
        min-width: 50vw;
        height: 20vh;
    }

    .portfolio_presentation-description_role {
        font-size: veriables.$medium-s;
    }

    .portfolio_presentation-description_name {
        font-size: veriables.$large;
    }

    .portfolio_presentation-description_description {
        font-size: veriables.$medium-s;
    }

    .portfolio_presentation-buttons {
        display: flex;
        flex-direction: column;
        width: 30vw;
        justify-content: flex-end;
        height: 20vh;
        align-items: flex-end;
    }
    
    .portfolio_presentation-buttons_previous {
        width: 8vw;
        height: 6vw;
        min-width: 48px;
        min-height: 36px;
    }
    
    .portfolio_presentation-buttons_next {
        margin-top: 2vw;
        width: 8vw;
        height: 6vw;
        min-width: 48px;
        min-height: 36px;
    }

    .portfolio_navigation {
        justify-content: flex-start;
        padding-left: 7vw;
    }

    .portfolio-link_A {
        margin-left: 4vw;
        width: 21vh;
        height: calc(21vh / 2.67);
    }

    .portfolio-link_B {
        margin-right: 4vw;
        width: 21vh;
        height: calc(21vh / 2.67);
    }
}


@media screen and (max-width: 426px) {
    .portfolio_presentation {
        margin-top: 2vh;
        height: 70vh;
    }

    .portfolio_presentation-picture-box {
        height: 45vh;
    }
    
    .portfolio_presentation-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 7vw;
        width: 50vw;
        min-width: 50vw;
        height: 20vh;
    }

    .portfolio_presentation-description_role {
        font-size: veriables.$small;
    }

    .portfolio_presentation-description_name {
        font-size: veriables.$medium-s;
    }

    .portfolio_presentation-description_description {
        font-size: veriables.$small;
    }

    .portfolio-link_A {
        margin-top: 7vh;
        margin-left: 2vw;
        width: 18vh;
        height: calc(18vh / 2.67);
    }

    .portfolio-link_B {
        margin-top: 7vh;
        margin-right: 2vw;
        width: 18vh;
        height: calc(18vh / 2.67);
    }

}