@use "veriables";

.landing_resources {
    display: none;
}

.landing_box {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: veriables.$brand-pink;
    background-image: url("../images/background.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    animation: easy-fade-in 0.3s ease-in-out;
}

.landing_break {
    display: none;
}

.left_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 68%;
    height: 100%;
    animation: fade-in 0.7s ease-in;
}

.right_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 32%;
    height: 100vh;
    animation: fade-in 0.9s  ease-in;
}

.title {
    font-size: veriables.$grand;
    font-weight: 700;
    color: veriables.$light;
    margin: 2.5vh 13%;
}

.title_break {
    display: none;
}

.subtitle {
    font-size: veriables.$very-large;
    font-weight: 300;
    color: veriables.$light;
    margin: 2.5vh 13%;
    animation-name: textflicker;
    animation-duration: 0.01s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.link_A {
    display: flex;
    flex-direction: column;
    width: 42vh;
    height: calc(42vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_A.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    transition: box-shadow 0.08s ease-in-out;
}

.link_A:hover {
    box-shadow: -4px -4px veriables.$light;
}

.link_A p {
    color: veriables.$brand-pink;
    font-size: veriables.$large;
    font-weight: 700;
}


.link_B {
    display: flex;
    flex-direction: column;
    width: 42vh;
    height: calc(42vh / 2.67);
    font-weight: 700;
    text-decoration: none;
    background-image: url("../images/button_link_B.svg");
    background-repeat: no-repeat;
    background-size: contain;
    justify-content: center;
    align-items: center;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    box-sizing: border-box;
    transition: box-shadow 0.08s ease-in-out;
}

.link_B:hover {
    box-shadow: 4px 4px veriables.$light;
}

.link_B p {
    color: veriables.$brand-pink;
    font-size: veriables.$large;
    font-weight: 700;
}


@keyframes fade-in {

    0% {
        opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes easy-fade-in {
    0% {
        opacity: 0.8;
    }
    100% {
        opacity: 1;
    }
}

@keyframes textflicker {
    0% {
        text-shadow: none;
    }
    50% {
      text-shadow: 1px 0 0 #ea36af, -2px 0 0 #75fa69;
    }
    51% {
      text-shadow: 2px 0.5px 2px #ea36af, -1px -0.5px 2px #75fa69;
    }
    100% {
        text-shadow: none;
    }
}

@media screen and (max-width: 992px) {
    .title {
        margin: 2vh 7%;
    }

    .subtitle {
        margin: 2vh 7%;
        font-size: veriables.$large;
    }

    .link_A {
        width: 31vh;
        height: calc(31vh / 2.67);
        font-weight: 500;
    }

    .link_B {
        width: 31vh;
        height: calc(31vh / 2.67);
        font-weight: 500;
    }
}

@media screen and (max-width: 768px) {
    .landing_box {
        flex-direction: column;
        background-image: url("../images/mobile_background.png");
    }
    .left_section {
        width: 100%;
        height: 60%;
    }
    .right_section {
        width: 100%;
        height: 40%;
        align-items: center;
        align-content: center;
    }
    .title_break {
        display: inline;
    }
}