@use "veriables";

.navigation_preload {
    display: none;
}

.navigation_box {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 15%;
    margin-top: 0;
}

.navigation_icons {
    margin: 3vw;
    display: flex;
    justify-content: space-between;
    width: 100%;
    animation: fade-in 0.7s ease-in;
}

.navigation_home {
    background-image: url(../images/logo.svg);
    width: calc(6vw * 0.54);
    height: calc(6vw * 0.54);
    min-width: 36px;
    min-height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 0.1s ease-in;
}

.navigation_home:hover {
    transform: scale(1.1);
}

.navigation_menu {
    background-image: url(../images/menu.svg);
    background-color: rgba(19, 21, 22, 0);
    width: 6vw;
    height: calc(6vw * 0.54);
    min-width: 67px;
    min-height: 36px;
    background-repeat: no-repeat;
    background-size: cover;
    border: none;
    cursor: pointer;
    transition: transform 0.1s ease-in;
}

.navigation_menu:hover {
    transform: scale(1.1);
}

.navigation_navigation-off {
    display: none;
}

.navigation_navigation-on {
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    background-color: veriables.$dark;
    color: veriables.$light;
    overflow: hidden;
    animation: open-menu 0.6s ease-out;
}

.navigation_close-menu-box {
    display: flex;
    justify-content: flex-end;
    height: 4vw;
    width: 100%;
    margin-top: 4vw;
}

.navigation_close-menu {
    background-image: url(../images/button_return.svg);
    background-color: veriables.$dark;
    background-repeat: no-repeat;
    background-size: cover;
    width: 3vw;
    height: 3vw;
    min-width: 36px;
    min-height: 36px;
    margin: 0 5vw;
    cursor: pointer;
    border: none;
    transition: transform 0.2s ease-in-out;
}

.navigation_close-menu:hover {
    transform: scale(1.1);
}

.navigation_content {
    display: flex;
    padding-left: 13vw;
}

.navigation_local {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 37vw;
    align-items: flex-start;
}

.navigation_local-link {
    text-decoration: none;
    color: veriables.$light;
    font-size: veriables.$very-large;
    font-weight: 300;
    margin: 1rem;
    border-bottom: solid;
    transition: border-image 0.2s ease-in-out, color 0.2s ease-in-out;
    border-image: linear-gradient(to right, veriables.$highlight 0%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%) 1;
}

.navigation_local-link:hover {
    color: veriables.$highlight;
    border-image: linear-gradient(to right, veriables.$highlight 66%, rgba(0, 0, 0, 0) 67%, rgba(0, 0, 0, 0) 100%) 1;
}

.navigation_line {
    border-left: 1px veriables.$light;
    height: 50vh;
    margin: 0 1.5vw;
    align-self: center;
}

.navigation_social {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: 1rem;
}

.navigation_email {
    text-decoration: none;
    color: veriables.$light;
    font-size: veriables.$medium;
    font-weight: 300;
    margin-bottom: 1rem;
    transition: color 0.2s ease-in-out;
}

.navigation_email:hover {
    color: veriables.$highlight;
}

.navigation_social-icons {
    display: flex;
    justify-content: space-between;
}

.navigation_social-icons a {
    transition: transform 0.2s ease-in-out;
}

.navigation_social-icons a:hover {
    transform: scale(1.1);
}

.navigation_github {
    background-image: url(../images/github_icon.svg);
    background-color: veriables.$dark;
    background-repeat: no-repeat;
    background-size: cover;
    width: 3vw;
    height: 3vw;
    min-width: 36px;
    min-height: 36px;
}

.navigation_behance {
    background-image: url(../images/behance_icon.svg);
    background-color: veriables.$dark;
    background-repeat: no-repeat;
    background-size: cover;
    width: 3vw;
    height: 3vw;
    min-width: 36px;
    min-height: 36px;
}

.navigation_dribbble {
    background-image: url(../images/dribbble_icon.svg);
    background-color: veriables.$dark;
    background-repeat: no-repeat;
    background-size: cover;
    width: 3vw;
    height: 3vw;
    min-width: 36px;
    min-height: 36px;
}

.navigation_instagram {
    background-image: url(../images/instagram_icon.svg);
    background-color: veriables.$dark;
    background-repeat: no-repeat;
    background-size: cover;
    width: 3vw;
    height: 3vw;
    min-width: 36px;
    min-height: 36px;
}

@keyframes open-menu {
    0% {
        transform: translateX(100vw) translateY(50vh) skew(45deg);
        height: 5vh;
        opacity: 0.7;
    }

    50% {
        transform: translateX(0) translateY(50vh);;
        height: 5vh;
    }
    100% {
        transform: translateY(0) skew(0deg);
        opacity: 1;
        height: 100vh;
    }
}

@media screen and (max-width: 1240px) {
    .navigation_content {
        margin-top: 10vh;
    }
}

@media screen and (max-width: 992px) {

    .navigation_icons {
        margin: 6vw;
    }
}

@media screen and (max-width: 768px) {
    .navigation_content {
        flex-direction: column;
        height: 80vh;
        padding-left: 0;
        margin-top: 5vh;
    }

    .navigation_line {
        border-right: none;
        border-left: none;
        border-top: 1px veriables.$light;
        height: 1px;
        width: 95vw;
        margin: 1.5vh 0;
        align-self: center;
    }

    .navigation_local {
        width: 100%;
        padding-bottom: 5vh;
    }

    .navigation_local-link {
        margin-left: 30%;
        font-size: veriables.$grand;
    }

    .navigation_social {
        padding: 0 20vw;
    }

    .navigation_email {
        font-size: veriables.$large;
    }
}

@media screen and (max-width: 480px) {
    .navigation_content {
        margin-top: 10vh;
    }

    .navigation_local-link {
        margin-left: 20%;
    }
}

@media screen and (max-width: 430px) {
    .navigation_local-link {
        margin-left: 20%;
    }

    .navigation_social {
        padding: 0 10vw;
    }
}